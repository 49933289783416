<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->

      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
    </v-skeleton-loader>
    <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />

    <v-card elevation="0">
      <v-card-title>
        Quarterly Plan Templates
        <router-link
          class="ml-4"
          v-if="
            permissions.quarterlyPlanTemplate &&
              permissions.quarterlyPlanTemplate.canCreate
          "
          :to="`/customers/${customerId}/planTemplates/new`"
        >
          <v-btn class="orange-bg white--text" small>New Plan Template</v-btn>
        </router-link>
      </v-card-title>
      <v-card-text ref="actionSection">
        <!-- Start Quarterly Plans section -->
        <v-progress-linear
          :active="plansLoading"
          :indeterminate="plansLoading"
          absolute
          bottom
        ></v-progress-linear>
        <div v-if="!plansLoading">
          <v-row v-if="sortedquarterlyPlanTemplates.length > 0">
            <div
              class="mx-4"
              v-for="(item, i) in sortedquarterlyPlanTemplates"
              :key="'o' + i"
            >
              <div>
                <div class="d-flex justify-space-between">
                  <div>
                    <router-link
                      class="blue--text"
                      :to="
                        `/customers/${customerId}/plans/new?sourceTemplateId=${item.id}`
                      "
                    >
                      Q{{ item.quarter }}
                    </router-link>
                  </div>
                  <div v-if="item.isActive">
                    <router-link
                      class="blue--text"
                      v-if="
                        permissions.quarterlyPlanTemplate &&
                          permissions.quarterlyPlanTemplate.canCreate
                      "
                      :to="
                        `/customers/${customerId}/planTemplates/${item.id}/edit`
                      "
                    >
                      Update
                    </router-link>
                    <v-btn
                      color="red white--text ml-2"
                      small
                      @click="showDeleteDialog(item)"
                      v-if="
                        permissions.quarterlyPlanTemplate &&
                          permissions.quarterlyPlanTemplate.canCreate
                      "
                    >
                      Delete
                    </v-btn>
                    <router-link
                      class="blue--text"
                      v-else
                      :to="
                        `/customers/${customerId}/planTemplates/${item.id}/view`
                      "
                    >
                      View
                    </router-link>
                  </div>
                </div>
                <router-link
                  class="blue--text"
                  :to="
                    `/customers/${customerId}/plans/new?sourceTemplateId=${item.id}`
                  "
                >
                  <v-img
                    height="200"
                    width="300"
                    :src="planPicture(item)"
                  ></v-img>
                </router-link>

                <p class="mt-2">
                  <router-link
                    class="blue--text"
                    :to="
                      `/customers/${customerId}/plans/new?sourceTemplateId=${item.id}`
                    "
                  >
                    {{ item.title }}
                  </router-link>
                </p>
              </div>
            </div>
          </v-row>
          <v-row v-else>
            <v-col class="text-center">
              <div class="text-subtitle-1 text-center">
                No Quarterly Plan Templates
              </div>
              <router-link
                v-if="
                  permissions.quarterlyPlanTemplate &&
                    permissions.quarterlyPlanTemplate.canCreate
                "
                :to="`/customers/${customerId}/planTemplates/new`"
              >
                <v-btn class="orange-bg white--text" small
                  >Create Quarterly Plan Template</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
        </div>

        <!-- End Quarterly Plans section -->
      </v-card-text>
    </v-card>

    <!-- </v-container> -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Delete Template
        </v-card-title>

        <v-card-text>
          <p class="h5">
            Are you sure you want to delete {{ templateNameToDelete }} template?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteTemplate">
            Delete
          </v-btn>

          <v-btn text @click="hideDeleteDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  DELETE,
  // POST_DATA,
  API_CUSTOMERS,
  API_QUARTERLY_PLAN_TEMPLATES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    quarterlyPlanTemplates: [],
    sortedquarterlyPlanTemplates: [],
    plansLoading: false,

    relatedCommunity: null,
    relatedCommunityItems: [],
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    permissions: {},
    activePlanId: null,
    deleteDialog: false,
    templateIdToDelete: 0,
    templateNameToDelete: ""
    // groupedTemplates: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
  },
  watch: {
    // activePlanId() {
    //   this.activatePlan();
    // }
  },
  async mounted() {
    // this.getPlans();
  },
  async created() {
    await this.getComunityInfo();
    await this.getPlans();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    async showDeleteDialog(item) {
      this.deleteDialog = true;
      this.templateIdToDelete = item.id;
      this.templateNameToDelete = item.title;
    },

    hideDeleteDialog() {
      this.deleteDialog = false;
      this.templateIdToDelete = "";
      this.templateNameToDelete = "";
    },

    async deleteTemplate() {
      let self = this;
      this.$store
        .dispatch(DELETE, {
          listName: API_QUARTERLY_PLAN_TEMPLATES,
          id: this.templateIdToDelete
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Template has been deleted";
            self.getPlans();
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.hideDeleteDialog();

          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },

    async getPlans() {
      this.plansLoading = true;
      // let self = this;

      let plansUrl = `${API_QUARTERLY_PLAN_TEMPLATES}?branch=${this.communityInfo?.branch?.name}`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: plansUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.quarterlyPlanTemplates = response.data.$values;

            this.sortedquarterlyPlanTemplates = this.quarterlyPlanTemplates
              .slice()
              .sort((a, b) => {
                return a.quarter > b.quarter;
              });

            // self.groupedTemplates = [];
            // self.sortedquarterlyPlanTemplates.forEach(el => {
            //   let obj = self.groupedTemplates.find(t => t.branch == el.branch);
            //   if (obj) {
            //     obj.templates.push(el);
            //   } else {
            //     obj = { branch: el.branch, templates: [] };
            //     obj.templates.push(el);
            //     self.groupedTemplates.push(obj);
            //   }
            // });
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.plansLoading = false;
        });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: "Quarterly Plan Templates" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: "Quarterly Plan Templates" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },

    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },

    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    planPicture() {
      let defaultPicture =
        "https://cdn.vuetifyjs.com/images/parallax/material2.jpg";

      return defaultPicture;
    }
  },
  computed: {}
};
</script>
