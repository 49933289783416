var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}})],1),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Quarterly Plan Templates "),(
          _vm.permissions.quarterlyPlanTemplate &&
            _vm.permissions.quarterlyPlanTemplate.canCreate
        )?_c('router-link',{staticClass:"ml-4",attrs:{"to":("/customers/" + _vm.customerId + "/planTemplates/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("New Plan Template")])],1):_vm._e()],1),_c('v-card-text',{ref:"actionSection"},[_c('v-progress-linear',{attrs:{"active":_vm.plansLoading,"indeterminate":_vm.plansLoading,"absolute":"","bottom":""}}),(!_vm.plansLoading)?_c('div',[(_vm.sortedquarterlyPlanTemplates.length > 0)?_c('v-row',_vm._l((_vm.sortedquarterlyPlanTemplates),function(item,i){return _c('div',{key:'o' + i,staticClass:"mx-4"},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/new?sourceTemplateId=" + (item.id))}},[_vm._v(" Q"+_vm._s(item.quarter)+" ")])],1),(item.isActive)?_c('div',[(
                      _vm.permissions.quarterlyPlanTemplate &&
                        _vm.permissions.quarterlyPlanTemplate.canCreate
                    )?_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/planTemplates/" + (item.id) + "/edit")}},[_vm._v(" Update ")]):_vm._e(),(
                      _vm.permissions.quarterlyPlanTemplate &&
                        _vm.permissions.quarterlyPlanTemplate.canCreate
                    )?_c('v-btn',{attrs:{"color":"red white--text ml-2","small":""},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v(" Delete ")]):_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/planTemplates/" + (item.id) + "/view")}},[_vm._v(" View ")])],1):_vm._e()]),_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/new?sourceTemplateId=" + (item.id))}},[_c('v-img',{attrs:{"height":"200","width":"300","src":_vm.planPicture(item)}})],1),_c('p',{staticClass:"mt-2"},[_c('router-link',{staticClass:"blue--text",attrs:{"to":("/customers/" + _vm.customerId + "/plans/new?sourceTemplateId=" + (item.id))}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)])}),0):_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"text-subtitle-1 text-center"},[_vm._v(" No Quarterly Plan Templates ")]),(
                _vm.permissions.quarterlyPlanTemplate &&
                  _vm.permissions.quarterlyPlanTemplate.canCreate
              )?_c('router-link',{attrs:{"to":("/customers/" + _vm.customerId + "/planTemplates/new")}},[_c('v-btn',{staticClass:"orange-bg white--text",attrs:{"small":""}},[_vm._v("Create Quarterly Plan Template")])],1):_vm._e()],1)],1)],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Delete Template ")]),_c('v-card-text',[_c('p',{staticClass:"h5"},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.templateNameToDelete)+" template? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteTemplate}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.hideDeleteDialog()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }